import { render, staticRenderFns } from "./Developer.vue?vue&type=template&id=54d31664&scoped=true&"
import script from "./Developer.vue?vue&type=script&lang=js&"
export * from "./Developer.vue?vue&type=script&lang=js&"
import style0 from "./Developer.vue?vue&type=style&index=0&id=54d31664&prod&scoped=true&lang=css&"
import style1 from "./Developer.vue?vue&type=style&index=1&id=54d31664&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d31664",
  null
  
)

export default component.exports